<template>
  <div>
    <admin-title></admin-title>


    <div class="container">
      <div class="page-title">
        <span>アカウント</span>
      </div>
      <p class="fu-title">{{type == 0 ? '新規作成' : 'アカウント詳細'}}</p>
      <div class="form-box">

        <el-form ref="ruleForm" :rules="rules" label-position="left" :model="detail" label-width="11rem">
          <el-form-item label="担当者" prop="username">
            <el-input :disabled="type == 1" placeholder="担当者名を入力" v-model="detail.username"></el-input>
          </el-form-item>


          <el-form-item v-if="type == 1||type==2" label="メールアドレス">
            <span class="mail">{{detail.mail}}</span>
          </el-form-item>
          <el-form-item prop="mail" v-else label="メールアドレス">
            <el-input placeholder="メールアドレスを入力" v-model="detail.mail"></el-input>
          </el-form-item>




          <el-form-item prop="password" label="パスワード">
            <el-input placeholder="半角英数字8文字以上入力" show-password v-model="detail.password"></el-input>
          </el-form-item>
          <el-form-item prop="confirmPwd" label="パスワード確認用">
            <el-input placeholder="上記パスワードを再入力" show-password v-model="detail.confirmPwd"></el-input>
          </el-form-item>
          <el-form-item v-if="userInfo.isAdmin == 1" label="管理者">
            <el-checkbox :disabled="type == 1" @change="updateIsAdmin()"  v-model="detail.isAdmin"></el-checkbox>
          </el-form-item>
          <el-form-item v-else label="管理者">
            <el-checkbox disabled   v-model="detail.isAdmin"></el-checkbox>
          </el-form-item>

        </el-form>

        <div class="buttons-add">
          <div  @click="register()" class="form-button yellow-btn">登録する</div>
          <div v-if="type!= 1" @click="back()" class="form-button green-btn">戻る</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { LOCAL_STORAGE_KEY } from "@/constants/common.js";
export default {
  name: "account",

  data() {
    var validatePass = (rule, value, callback) => {
      if(this.type == 2) {
        this.$refs.ruleForm.validateField('confirmPwd');
        callback();
      } else {
        //「パスワード」が英数含む8文字以上20文字以下ご入力ください
        if (!value) {
          callback(new Error('「パスワード」が入力されていません。'));
        } else {
          if (this.detail.password !== '') {
            this.$refs.ruleForm.validateField('confirmPwd');
          }
          callback();
        }
      }

    };

    var validatePass2 = (rule, value, callback) => {
      if(this.type == 2) {
        if (value !== this.detail.password) {
          callback(new Error('2回入力したパスワードが一致しません'));
        } else {
          callback();
        }
      } else {
        if (!value) {
          callback(new Error('「パスワード確認用」が入力されていません。'));
        } else if (value !== this.detail.password) {
          callback(new Error('2回入力したパスワードが一致しません'));
        } else {
          callback();
        }
      }

    };

    return {
      form: {},

      input3: '',

      type: 0,
      status: 0,

      userInfo: {},

      tableData: [],

      detail: {},

      rules: {
        password: [
          { validator: validatePass, trigger: 'blur' },
          {
            pattern: /^(?=.*\d+)(?=.*[A-Za-z]+)[\w]{6,16}$/,
            message: "英数字両方含む8文字以上20文字以内を入力してください",
            trigger: "blur",
          },
          { min: 8, max: 20, message: '「パスワード」が英数含む8文字以上20文字以下ご入力ください', trigger: 'blur' }
        ],
        confirmPwd: [
          { validator: validatePass2, trigger: 'blur' },

        ],
        mail: [
          { required: true, message: '「メールアドレス」が入力されていません。', trigger: 'blur' },
          { type: 'email', message: '正しいメールアドレスを入力してください', trigger: ['blur', 'change'] }
        ],
        username: [
          { required: true, message: '「担当者」が入力されていません。', trigger: 'blur' },
        ]

      }

    }
  },
  watch: {
    //监听路由地址的改变
    $route:{
      immediate:true,
      handler(){
        if(this.$route.query.type){//需要监听的参数
          this.type = this.$route.query.type;
          console.log("###");
          this.getDetail();
        }
        else if(this.$route.query.id){
          this.type=2;
          this.getUserInfo();
        }
      }
    }
  },
  mounted() {
    console.log("###");

    this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));




  },

  methods: {
    search() {

    },


    updateIsAdmin() {
      // console.log(this.detail)
      if(this.detail.isAdmin) return;
      let that = this;
      this.$confirm('管理者権限が外れています。<br/>よろしいですか？', '', {
        confirmButtonText: 'はい',
        showCancelButton: 'キャンセル',
        center: true,
        dangerouslyUseHTMLString: true
      }).then(() => {

      }).catch(() => {
        this.detail.isAdmin = true;
      });
    },


    async getDetail() {
      this.userInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY.USER_INFO));
      let res = await this.$axios({url: `/compUser/detail`, data: {token: this.userInfo.token}, method: "post"});
      this.detail = res.data;
      this.detail.isAdmin=this.detail.isAdmin==1;

    },
    async getUserInfo() {
      let res = await this.$axios({url: `/compUser/userInfo`, data: {id: this.$route.query.id}, method: "post"});
      this.detail = res.data;
      this.detail.isAdmin=this.detail.isAdmin==1;
    },

    back(){
      this.$router.back();
    },
    register() {
      let that = this;

      this.$refs['ruleForm'].validate((valid) => {
        console.log(valid)
        if (valid) {
          that.add();
        } else {
          return false;
        }
      });

    },

    async add() {
      if(this.type == 0)
      {
        let res = await this.$axios({url: `/compUser/add`, data: {
            token: this.userInfo.token,
            username: this.detail.username,
            password: this.detail.password,
            mail: this.detail.mail,
            isAdmin: this.detail.isAdmin ? 1 : 0
          }, method: "post"});

        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
          this.$router.back();
        }
      }
      else{
        let data = {
          token: this.userInfo.token,
          username: this.detail.username,
          id: this.detail.id,
          isAdmin: this.detail.isAdmin ? 1 : 0
        }

        if(this.detail.password) {
          data.password = this.detail.password;
        }

        let res = await this.$axios({url: `/compUser/update`, data: data, method: "post"});

        if(res.code == '000') {
          this.$message({
            type: 'success',
            message: res.message
          });
          if(res.data && ((this.$route.query.id == this.userInfo.id)||this.type==1)){
            this.userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
            this.userInfo.token=res.data;
            localStorage.setItem("USER_INFO",JSON.stringify(this.userInfo));
            localStorage.RPA_TOKEN=res.data;
          }
          console.log("###",this.type)
          if(this.type==2){
            this.$router.back();
          }
        }
      }
    }




  }
}
</script>

<style scoped src="../../style/account.css">

</style>
